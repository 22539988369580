<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo pdng_28" >
          <!-- <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            EL Taskforce
          </h2> -->

          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:240px"
            class="site_logo_mobile"
          />

        </b-link>

        <b-card-title class="mb-1" style="font-size: 20px; text-align: center">
          Welcome to EL TaskForce Order Management!
        </b-card-title>

        <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            fade
            class="mb-0"
          >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <!-- form -->
        
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- Stall No. -->
                  <b-form-group label="Username">

                  <b-form-input
                    id="username"
                    v-model="username"
                    class="form-control-merge"
                    
                    name="username"
                    placeholder="Username"
                  />
              
                  <!--<v-select 
                    v-if="!route"
                    v-model="selected_stall"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="filter_stall"
                    :options="filter_stall"
                    placeholder="Select Stall No."
            />

                    <v-select 
                    v-if="route"
                    v-model="selected_stall"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="filter_stall"
                    :options="filter_stall"
                    placeholder="Select Stall No."
                    disabled
            />-->

                  </b-form-group>

            <!-- password -->
            <b-form-group label="Password">
              <div class="d-flex justify-content-between">
                <!-- <b-link :to="{}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              
                <b-input-group
                  class="input-group-merge"
                  
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
               
            </b-form-group>

            <b-button
              variant="secondary"
              type="submit"
              block
            >
              Sign in
            </b-button>
            <br>
            <b-form-group class="d-flex justify-content-between">
                            <div>
            <span><b-link href="" :to="{name: 'forgot-password'}" >Forgot Password?</b-link></span>
                            </div>
            
            </b-form-group>

          </b-form>
        

        <b-card-text class="text-center mt-2">
          <!-- <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link> -->
        </b-card-text>

        <div class="divider my-2">
          <!-- <div class="divider-text">
            or
          </div> -->
        </div>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert,BImg
} from 'bootstrap-vue'

import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { LOGIN } from "../store/actions.type"
import { POST_API } from "../store/actions.type"
import vSelect from 'vue-select'
var CryptoJS = require("crypto-js");

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BAlert,
    BImg,
    vSelect,
    CryptoJS
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      appLogoImage : require('@/assets/images/logo/task-force-full.png'),
      stall_no: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      showDismissibleAlert:false,
      error_message:'',
      //stall: [],
      //filter_stall: [],
      username: '',
      route:this.$route.params.id,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      
      this.$store.dispatch(LOGIN, {
                        data:{
                            username:this.username.toLowerCase(),
                            password:this.password,
                            account_type:['Admin', 'Customer']
                        },
                        api:'/api/client-login'
                    })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.showDismissibleAlert = false;
                this.error_message = this.$store.getters.getErrors;
                setTimeout(() => this.showDismissibleAlert = true, 100)
            } else {
              
              var userData = this.$store.getters.currentUser;
              
              userData.ability = [{
                action: "manage", subject: "all"
              }];

              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Login Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    if (userData.account_type == 'Admin') {
                      window.location.replace('/admin/manage-order');
                    }else{
                      window.location.replace('/client/place-order');
                    }
                    })
            }       
        }); 
    },
     /* getStallData(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/get-stall'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.stall = data;

                data.forEach(i => {
                  this.filter_stall.push(i.no)
                })
                // If route.params != null, auto select the dropdown option. This is for when users scan QR code to access the page.
                if (this.route != null)
                {
                  this.selected_stall = this.stall[this.route-1].no 
                }
                return this.stall;
            }
        });
    }, */
        filterTable(){
          this.$refs.refUserListTable.refresh();
        },
  },
    mounted(){
    //this.getStallData();
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

